import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

import {ContentSnippet} from '../response/Content'

export const getContentSnippets = (api: AxiosInstance): Promise<AxiosResponse<ContentSnippet[]>> =>
  api.get('/snippets')

export const getContentSnippetByPlantId =
  (api: AxiosInstance) =>
  (plantId: string, axiosConfig?: AxiosRequestConfig): Promise<AxiosResponse<ContentSnippet[]>> =>
    api.get(`/snippets?plantId=${encodeURIComponent(plantId)}`, axiosConfig)

type SnippetType = 'plantQCTitleText' | 'plantQCNameText' | 'plantMaterialText'

export const createSnippet =
  (api: AxiosInstance) =>
  (
    data: {
      type: SnippetType
      body: string
      country: string
      language: string
      plantId: string
      materialNumber?: string
    },
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<ContentSnippet>> =>
    api.post('/snippets', data, axiosConfig)

export const updateSnippet =
  (api: AxiosInstance) =>
  (
    data: {
      id: string
      type: SnippetType
      body: string
      country: string
      language: string
      plantId: string
      materialNumber?: string
    },
    axiosConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse<ContentSnippet>> => {
    const {id, ...payload} = data
    return api.put(`/snippets/${id}`, payload, axiosConfig)
  }

export const deleteSnippet =
  (api: AxiosInstance) =>
  (id: string, axiosConfig?: AxiosRequestConfig): Promise<AxiosResponse<ContentSnippet>> =>
    api.delete(`/snippets/${id}`, axiosConfig)
